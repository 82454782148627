import React from 'react';
import './solucoes.css';
import SolutionsHeroSection from '../components/SolutionsHeroSection/SolutionsHeroSection';
import SolutionsFeaturesSection from '../components/SolutionsFeaturesSection/SolutionsFeaturesSection';
import SolutionsBenefitsSection from '../components/SolutionsBenefitsSection/SolutionsBenefitsSection';
import SolutionsIntegrationSection from '../components/SolutionsIntegrationSection/SolutionsIntegrationSection';
import SolutionsSecuritySection from '../components/SolutionsSecuritySection/SolutionsSecuritySection';
import SolutionsTestimonialsSection from '../components/SolutionsTestimonialsSection/SolutionsTestimonialsSection';
import SolutionsCTASection from '../components/SolutionsCTASection/SolutionsCTASection';
import Seo from '../components/Seo';
import Layout from '../components/layout';
import Breadcrumbs from '../components/Breadcrumbs/breadcrumbs';

const Solutions = () => {
  const crumbs = [
    { path: '/', label: 'Página Inicial' },
    { path: '/solucoes', label: 'Soluções' }
  ];

  return (
    <Layout>
      <div className="solutions-container">
        <Seo
          title="Soluções para Gestão de Clínicas - Prontuário Fácil"
          description="Descubra as soluções completas do Prontuário Fácil para otimizar a gestão da sua clínica ou consultório. Agendamento online, prontuário eletrônico, financeiro, telemedicina e muito mais."
          keywords="prontuário eletrônico, software médico, gestão de clínicas, agendamento online, telemedicina, segurança de dados, integrações"
        />
        <Breadcrumbs crumbs={crumbs} />
        <main className="content">
          <SolutionsHeroSection />
          <SolutionsFeaturesSection />
          <SolutionsBenefitsSection />
          <SolutionsIntegrationSection />
          <SolutionsSecuritySection />
          <SolutionsTestimonialsSection />
          <SolutionsCTASection />
        </main>
      </div>
    </Layout>
  );
};

export default Solutions;
