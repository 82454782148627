import React from 'react';
import { FiThumbsUp, FiClock, FiShield } from 'react-icons/fi';
import './SolutionsBenefitsSection.css';

const SolutionsBenefitsSection = () => {
    return (
        <section className="solutions-benefits-section" aria-labelledby="benefits-title">
            <h2 id="benefits-title" className="section-title">Benefícios do Prontuário Fácil</h2>
            <div className="benefits-container" role="list" aria-label="Lista de benefícios do Prontuário Fácil">
                <div className="benefit-item" role="listitem" aria-labelledby="efficiency-title">
                    <FiThumbsUp className="benefit-icon" aria-hidden="true" />
                    <h3 id="efficiency-title">Eficiência Operacional</h3>
                    <p>Reduza o tempo gasto em tarefas administrativas e concentre-se mais no atendimento ao paciente.</p>
                </div>
                <div className="benefit-item" role="listitem" aria-labelledby="speed-title">
                    <FiClock className="benefit-icon" aria-hidden="true" />
                    <h3 id="speed-title">Rapidez no Atendimento</h3>
                    <p>Agilize o processo de agendamento e reduza o tempo de espera para os pacientes.</p>
                </div>
                <div className="benefit-item" role="listitem" aria-labelledby="security-title">
                    <FiShield className="benefit-icon" aria-hidden="true" />
                    <h3 id="security-title">Segurança dos Dados</h3>
                    <p>Garantia de proteção de dados com a mais alta segurança e conformidade com a LGPD.</p>
                </div>
            </div>
        </section>
    );
};

export default SolutionsBenefitsSection;
