import React from 'react';
import { FiLink } from 'react-icons/fi';
import './SolutionsIntegrationSection.css';

const SolutionsIntegrationSection = () => {
    return (
        <section className="solutions-integration-section" aria-labelledby="integration-heading">
            <div className="container">
                <h2 id="integration-heading" className="section-title">Integração Simples e Poderosa</h2>
                <div className="integration-container">
                    <article className="integration-item" aria-labelledby="integration-lab-systems">
                        <FiLink className="integration-icon" aria-hidden="true" />
                        <h3 id="integration-lab-systems">Sistemas de Laboratório</h3>
                        <p>Integração direta com os principais sistemas de laboratório para acesso rápido a resultados de exames.</p>
                    </article>
                    <article className="integration-item" aria-labelledby="integration-scheduling-systems">
                        <FiLink className="integration-icon" aria-hidden="true" />
                        <h3 id="integration-scheduling-systems">Sistemas de Agendamento</h3>
                        <p>Conecte-se facilmente a sistemas de agendamento para uma gestão de consultas mais eficiente.</p>
                    </article>
                    <article className="integration-item" aria-labelledby="integration-billing-systems">
                        <FiLink className="integration-icon" aria-hidden="true" />
                        <h3 id="integration-billing-systems">Sistemas de Faturamento</h3>
                        <p>Automatize o faturamento e reduza erros com integrações a sistemas de faturamento e cobrança.</p>
                    </article>
                </div>
            </div>
        </section>
    );
};

export default SolutionsIntegrationSection;
