import React from 'react';
import './SolutionsHeroSection.css';
import { Link } from 'gatsby';

const SolutionsHeroSection = () => {
    return (
        <section className="solutions-hero-section" aria-labelledby="solutions-hero-title">
            <div className="container">
                <h1 id="solutions-hero-title">Transforme a Gestão de Sua Clínica com Prontuário Fácil</h1>
                <p>Descubra como nossa solução completa pode simplificar todos os aspectos da sua prática médica.</p>
                <Link to="/novo-usuario" id="cta-principal" className="about-hero-cta">Saiba Mais</Link>
            </div>
        </section>
    );
};

export default SolutionsHeroSection;
