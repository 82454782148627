import React from 'react';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';
import './SolutionsTestimonialsSection.css';

const SolutionsTestimonialsSection = () => {
    return (
        <section className="solutions-testimonials-section" aria-labelledby="testimonials-heading">
            <div className="container">
                <h2 id="testimonials-heading" className="section-title">O que Nossos Clientes Dizem</h2>
                <div className="testimonials-carousel">
                    <blockquote className="testimonial-item" aria-labelledby="testimonial-author">
                        <FaQuoteLeft className="quote-icon" aria-hidden="true" />
                        <p className="testimonial-text">
                            "Adotar o Prontuário Fácil foi decisivo para melhorar a eficiência operacional da nossa clínica. O suporte ao cliente é incrível!"
                        </p>
                        <FaQuoteRight className="quote-icon" aria-hidden="true" />
                        <footer>
                            <p id="testimonial-author" className="testimonial-author">— Dr. Ana Beatriz, Ginecologista</p>
                        </footer>
                    </blockquote>
                </div>
            </div>
        </section>
    );
};

export default SolutionsTestimonialsSection;
