import React from 'react';
import { FiCalendar, FiUserCheck, FiActivity } from 'react-icons/fi';
import './SolutionsFeaturesSection.css';

const SolutionsFeaturesSection = () => {
    return (
        <section className="solutions-features-section" aria-labelledby="features-title">
            <div className="container">
                <h2 id="features-title" className="section-title">Funcionalidades Chave</h2>
                <div className="features-container" role="list">
                    <div className="feature-card" role="listitem">
                        <FiCalendar className="feature-icon" aria-hidden="true" />
                        <h3>Agendamento Inteligente</h3>
                        <p>Automatize a marcação de consultas e maximize a eficiência do agendamento com nosso sistema inteligente.</p>
                    </div>
                    <div className="feature-card" role="listitem">
                        <FiUserCheck className="feature-icon" aria-hidden="true" />
                        <h3>Gestão de Pacientes</h3>
                        <p>Gerencie informações de pacientes com facilidade, desde o cadastro até o acompanhamento do tratamento.</p>
                    </div>
                    <div className="feature-card" role="listitem">
                        <FiActivity className="feature-icon" aria-hidden="true" />
                        <h3>Monitoramento em Tempo Real</h3>
                        <p>Acompanhe os indicadores de saúde dos pacientes em tempo real para uma resposta rápida a qualquer necessidade.</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SolutionsFeaturesSection;
