import React from 'react';
import { FiShield } from 'react-icons/fi';
import './SolutionsSecuritySection.css';

const SolutionsSecuritySection = () => {
    return (
        <section className="solutions-security-section" aria-labelledby="security-heading">
            <div className="container">
                <h2 id="security-heading" className="section-title">Segurança e Conformidade</h2>
                <div className="security-container">
                    <article className="security-item" aria-labelledby="advanced-encryption">
                        <FiShield className="security-icon" aria-hidden="true" />
                        <h3 id="advanced-encryption">Criptografia Avançada</h3>
                        <p>Utilizamos criptografia de ponta-a-ponta para garantir que todos os dados dos pacientes permaneçam seguros e privados.</p>
                    </article>
                    <article className="security-item" aria-labelledby="lgpd-compliance">
                        <FiShield className="security-icon" aria-hidden="true" />
                        <h3 id="lgpd-compliance">Conformidade com a LGPD</h3>
                        <p>Nosso sistema está totalmente adaptado às normas da LGPD, assegurando que o tratamento de dados pessoais seja feito de forma legal e transparente.</p>
                    </article>
                    <article className="security-item" aria-labelledby="constant-monitoring">
                        <FiShield className="security-icon" aria-hidden="true" />
                        <h3 id="constant-monitoring">Monitoramento Constante</h3>
                        <p>Mantemos monitoramento constante de nossos sistemas para detectar e responder rapidamente a qualquer ameaça de segurança.</p>
                    </article>
                </div>
            </div>
        </section>
    );
};

export default SolutionsSecuritySection;
